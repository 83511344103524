<template>
  <div class="main ant-card" :style="{top:(screenHeight/2-230)+'px',right:layoutRight+'px'}">
    <div class="title">{{ $t('main.title') }}</div>
    <a-form
      id="formLogin"
      class="user-layout-login"
      ref="formLogin"
      :form="form"
      @submit="handleSubmit"
    >

      <a-form-item>
        <a-input
          size="large"
          type="text"
          :placeholder="$t('user.account')"
          v-decorator="[
            'account',
            {rules: [{ required: true, type: 'email', message: $t('user.email.tip') }, { validator: handleUsernameOrEmail }], validateTrigger: 'change'}
          ]"
        >
          <a-icon slot="prefix" type="user" :style="{ color: 'rgba(0,0,0,.25)' }" />
        </a-input>
      </a-form-item>

      <a-form-item>
        <a-input
          size="large"
          type="password"
          autocomplete="false"
          :placeholder="$t('user.password')"
          v-decorator="[
            'passwd',
            {rules: [{ required: true, message: $t('user.password.tip') }], validateTrigger: 'blur'}
          ]"
        >
          <a-icon slot="prefix" type="lock" :style="{ color: 'rgba(0,0,0,.25)' }" />
        </a-input>
      </a-form-item>
      <a-row :gutter="6">
        <a-col :xs="14" :md="15" :lg="16">
          <a-form-item>
            <a-input
              size="large"
              autocomplete="false"
              :placeholder="$t('user.captcha')"
              v-decorator="[
                'captcha',
                {rules: [{ required: true, message: $t('user.captcha.tip') }]}
              ]"
            >
              <a-icon slot="prefix" type="safety-certificate" :style="{ color: 'rgba(0,0,0,.25)' }" />
            </a-input>
          </a-form-item>
        </a-col>
        <a-col :xs="10" :md="9" :lg="8">
          <img
            :src="captchaUrl"
            style="height: 36px;margin-left: 16px;position:relative;top:2px;cursor: pointer;"
            @click="refreshCaptcha">
        </a-col>

        <a-form-item style="margin-top:24px">
          <a-button
            size="large"
            type="primary"
            htmlType="submit"
            class="login-button"
            :loading="state.loginBtn"
            :disabled="state.loginBtn"
          >{{ $t('user.login') }}
          </a-button>
        </a-form-item>

      </a-row>

    </a-form>
    <div>
      <a-checkbox v-model="readed">{{ $t('user.readAndAgree') }}<a
        href="https://www.reflexwireless.com/en/terms-and-conditions-japanese/"
        target="_blank">{{ $t('user.UserServiceAgreement') }}</a></a-checkbox>
    </div>
    <div class="user-login-other">
      <div
        class="g_id_signin float-left"
        data-locale="ja"
        data-type="standard"
        data-size="large"
        data-theme="outline"
        data-text="sign_in_with"
        data-shape="rectangular"
        data-logo_alignment="left">
      </div>
      <router-link class="register" :to="{ name: 'register' }">{{ $t('user.regnow') }}</router-link>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */

import md5 from 'md5'
import axios from 'axios'
import TwoStepCaptcha from '@/components/tools/TwoStepCaptcha'
import { mapActions } from 'vuex'
import { timeFix } from '@/utils/util'
import store from '../../store'
import Vue from 'vue'
import { ACCESS_TOKEN, USERID } from '@/store/mutation-types'

let timer = null

export default {
  components: {
    TwoStepCaptcha
  },
  data () {
    return {
      readed: false,
      screenHeight: 0,
      customActiveKey: 'tab1',
      loginBtn: false,
      // login type: 0 email, 1 username, 2 telephone
      loginType: 0,
      requiredTwoStepCaptcha: false,
      stepCaptchaVisible: false,
      form: this.$form.createForm(this),
      state: {
        time: 60,
        loginBtn: false,
        // login type: 0 email, 1 username, 2 telephone
        loginType: 0,
        smsSendBtn: false
      },
      code: null,
      captchaUrl: null,
      qrUrl: null,
      layoutRight: document.body.clientWidth > 1200 ? 100 : 0
    }
  },
  created () {
    this.screenHeight = document.body.clientHeight
    var _this = this
    this.loadImageCaptcha()
    this.$nextTick(() => {
      this.initSocketClient()
    })

    timer = setInterval(function () {
      _this.loadImageCaptcha()
    }, 50000)
  },
  methods: {
    ...mapActions(['Login', 'Logout']),
    // handler

    refreshQr (clientId, authCode) {
      this.qrUrl = `/user/auth/qr?action=login&clientId=${clientId}&authCode=${authCode}&time=${(new Date()).getTime()}`
    },
    refreshCaptcha () {
      if (timer) {
        clearInterval(timer)
      }
      var _this = this
      this.loadImageCaptcha()
      timer = setInterval(function () {
        _this.loadImageCaptcha()
      }, 50000)
    },
    initSocketClient () {
      axios.post('/user/qr/client', { action: 'login' }).then(res => {
        var resp = res.data
        if (resp.code === 0) {
          var data = resp.data

          this.initSocket(data.clientId, data.authCode)
          this.refreshQr(data.clientId, data.authCode)
          const loginIdEle = document.getElementById('g_id_onload')
          loginIdEle.setAttribute('data-your_own_param_1_to_login', data.clientId)
          loginIdEle.setAttribute('data-your_own_param_2_to_login', data.authCode)
          var secScript = document.createElement('script')
          secScript.setAttribute('type', 'text/javascript')
          secScript.setAttribute('async', 'true')
          secScript.setAttribute('src', 'https://accounts.google.com/gsi/client?hl=ja')
          document.body.insertBefore(secScript, document.body.lastChild)
        }
      })
    },
    initSocket (clientId, authCode) {
      const wsuri = 'wss://www.breathmeters.net:444/qr/login/' + clientId + '/' + authCode
      this.websock = new WebSocket(wsuri)
      this.websock.onopen = this.websocketonopen
      this.websock.onmessage = this.websocketonmessage
      this.websock.onerror = this.websocketonerror
      this.websock.onclose = this.websocketclose
    },
    websocketonopen () {
      console.log('open')
    },
    websocketonmessage (msg) {
      try {
        var resp = JSON.parse(msg.data)
        if (resp.event === 'googleLogin') {
          const result = resp.data
          Vue.ls.set(ACCESS_TOKEN, result.session, 7 * 24 * 60 * 60 * 1000)
          Vue.ls.set(USERID, result.user_id, 7 * 24 * 60 * 60 * 1000)
          this.loginSuccess()
        }
      } catch (e) {

      }
    },
    websocketonerror () {
      console.log('error')
    },
    websocketclose () {
      console.log('close')
    },
    handleUsernameOrEmail (rule, value, callback) {
      const { state } = this
      const regex = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+((\.[a-zA-Z0-9_-]{2,3}){1,2})$/
      if (regex.test(value)) {
        state.loginType = 0
      } else {
        state.loginType = 1
      }
      callback()
    },
    handleSubmit (e) {
      e.preventDefault()
      const {
        form: { validateFields },
        state,
        customActiveKey
      } = this

      state.loginBtn = true

      const validateFieldsKey = customActiveKey === 'tab1' ? ['account', 'passwd', 'captcha'] : ['mobile', 'captcha']

      validateFields(validateFieldsKey, { force: true }, (err, values) => {
        if (!err) {
          if (!this.readed) {
            this.$message.error(this.$t('user.agreement.read'))
            state.loginBtn = false
            return
          }
          const loginParams = { ...values }
          delete loginParams.username
          loginParams.appid = 'com.reflex.alc.manager.net'
          loginParams.code = this.code
          loginParams.passwd = md5(values.passwd)
          store.dispatch('Login', loginParams)
            .then((res) => this.loginSuccess(res))
            .catch(err => this.requestFailed(err))
            .finally(() => {
              state.loginBtn = false
            })
        } else {
          setTimeout(() => {
            state.loginBtn = false
          }, 600)
        }
      })
    },
    getCaptcha (e) {
      e.preventDefault()
      const { form: { validateFields }, state } = this

      validateFields(['mobile'], { force: true }, (err, values) => {
        if (!err) {
          state.smsSendBtn = true

          const interval = window.setInterval(() => {
            if (state.time-- <= 0) {
              state.time = 60
              state.smsSendBtn = false
              window.clearInterval(interval)
            }
          }, 1000)
        }
      })
    },
    genRandomChars (len, onlyNum) {
      const chars = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']
      let code = ''
      for (let i = 0; i < len; i++) {
        if (onlyNum) {
          code += chars[parseInt(Math.random() * 10)]
        } else {
          code += chars[parseInt(Math.random() * 62)]
        }
      }
      return code
    },
    loadImageCaptcha () {
      axios.post('/user/login/captcha/auth/code', {}).then(res => {
        const resp = res.data
        if (resp.code === 0) {
          this.code = resp.data
          this.captchaUrl = '/user/login/captcha/gen?code=' + resp.data + '&color=white'
        }
      })
    },
    loginSuccess () {
      this.$router.push({ name: 'record' })
    },
    requestFailed (err) {
      this.$notification['error']({
        message: 'Error',
        description: (err && err.msg && err.msg.ja) ? err.msg.ja : 'There was an error with the request, please try again later.',
        duration: 4
      })
      this.refreshCaptcha()
    }
  }
}
</script>

<style lang='less' scoped>
.main {
  background: rgba(255, 255, 255, 0.9);
  padding: 10px 30px 20px 30px;
  backdrop-filter: blur(15px);
  border-radius: 8px;
  position: absolute;
  width: 442px !important;

  .title {
    text-align: center;
    margin: 20px 0;
    font-weight: bold;
    font-size: 1.2rem;
  }
}

.user-layout-login {
  label {
    font-size: 14px;
  }

  .getCaptcha {
    display: block;
    width: 100%;
    height: 40px;
  }

  .forge-password {
    font-size: 14px;
  }

  button.login-button {
    padding: 0 15px;
    font-size: 16px;
    height: 40px;
    width: 100%;
  }

}

.user-login-other {
  text-align: left;
  margin-top: 24px;
  line-height: 22px;

  .item-icon {
    font-size: 24px;
    color: rgba(0, 0, 0, 0.2);
    margin-left: 16px;
    vertical-align: middle;
    cursor: pointer;
    transition: color 0.3s;

    &:hover {
      color: #1890ff;
    }
  }

  .register {
    float: right;
    line-height: 36px;
  }
}
</style>
